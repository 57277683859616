import { get, post } from '../../../../api/index';
export const courseInfo = params => get(`/api/course/v1/detail/courseInfo`, params); // 课程详情
export const portalInfo = params => get(`/api/course/v1/portal/info`, params); // 课程门户详情
//课单
export const addCourseToKD = params => post(`/api/mycourse/v1/list/addlist`, params); // 课程加入课单
export const myCourseList = params => get(`/api/mycourse/v1/list`, params); // 我的课单
export const myCourseKDAdd = params => post(`/api/mycourse/v1/list/add`, params); // 添加课单


// 课程笔记
export const noteList = params => get(`/api/notes/v1/coursenoteslist`, params); // 课程笔记列表
export const noteAdd = params => post(`/api/notes/v1/notesadd`, params); // 课程笔记新建
export const noteEdit = params => post(`/api/notes/v1/notesedit`, params); // 课程笔记编辑
export const noteDel = params => get(`/api/notes/v1/notesdel`, params); // 课程笔记删除

//课程评价
export const evalList = params => get(`/api/course/v1/appraisal/list`, params); // 课程评价列表
export const evalAdd = params => post(`/api/course/v1/appraisal/add`, params); // 课程评价提交

//课件安排
export const lessonList = params => get(`/api/course/v1/detail/lessonlist`, params); // 课件列表

//课后资料
export const fileList = params => get(`/api/uploader/v1/file_list`, params);
export const downFile = params => get(`/api/course/v1/detail/materialinfo`, params); //下载
export const ebookinfo = params => get(`/api/course/v1/detail/ebookinfo`, params); //下载
export const downFiles = params => get(`/api/uploader/v1/down_file`, params);
//直播
export const liveinfo = params => get(`/api/course/v1/detail/liveinfo`, params);
