<template>
  <div class="studyLog">
    <div class="studyLogtit clearfix">
      <h6>学习记录</h6>
      <span @click="clear"><i class="iconfont icon-study_yc"></i>清空记录</span>
    </div>
    <div class="studyLogdiv">
      <!-- 今天 -->
      <div class="studyLogdivysj" v-if="todayList.length">
        <div class="studyLogdivList">
          <h6 class="studyLogdivListh6">今天</h6>
          <ul class="studyLogdivListoul">
            <li
              class="clearfix"
              v-for="(item, index) in todayList"
              :key="index"
            >
              <dl class="studyLogdivListouldl clearfix">
                <dt><img :src="item.course_image" alt="" /></dt>
                <dd>
                  <h6>
                    <i>{{ item.course_type }}</i
                    ><span style="cursor: pointer" @click="jump_course(item)">{{
                      item.course_title
                    }}</span>
                  </h6>
                  <p>
                    <i v-if="item.lesson_type == 1">视频</i>
                    <i v-if="item.lesson_type == 2">直播</i>
                    <i v-if="item.lesson_type == 3">试卷</i>
                    <i v-if="item.lesson_type == 4">资料</i>
                    <i v-if="item.lesson_type == 5">作业</i>
                    <i v-if="item.lesson_type == 6">问卷</i>
                    <i v-if="item.lesson_type == 7">回放</i>
                    <i v-if="item.lesson_type == 8">电子书</i>
                    <i v-if="item.lesson_type == 9">签到</i>
                    <span style="cursor: pointer" @click="jump_lesson(item)">{{
                      item.lesson_title
                    }}</span>
                  </p>
                </dd>
              </dl>
              <div @click="jump_lesson(item)">
                <span
                  class="studyLogdivListoulsp"
                  v-if="item.lesson_type == 1 || item.lesson_type == 7"
                >
                  观看至{{ item.study_time }}
                </span>
                <span
                  class="studyLogdivListoulsp"
                  :class="
                    item.live_status == '即将直播'
                      ? 'jjzb'
                      : item.live_status == '未开始'
                      ? 'wks'
                      : item.live_status == '已结束'
                      ? 'yjs'
                      : ''
                  "
                  v-if="item.lesson_type == 2 && item.is_replay == 0"
                >
                  <img
                    v-if="item.live_status == '直播中'"
                    src="../../../../assets/images/living_blue.gif"
                    alt=""
                  />{{ item.live_status }}
                </span>
                <span
                  class="studyLogdivListoulsp"
                  v-if="item.lesson_type == 3 || item.lesson_type == 5"
                >
                  <span v-if="item.answer_id">
                    <span v-if="item.paper_finished != 1"> 继续做答 </span>
                    <span v-if="item.paper_finished == 1 && item.comment == ''"
                      >待点评</span
                    >
                    <span v-if="item.paper_finished == 1 && item.fraction">{{
                      item.fraction
                    }}</span>
                  </span>
                  <span v-else>查看</span>
                </span>
                <span
                  class="studyLogdivListoulsp"
                  v-if="
                    item.lesson_type == 4 ||
                    item.lesson_type == 6 ||
                    item.lesson_type == 8 ||
                    (item.lesson_type == 2 && item.is_replay == 1)
                  "
                >
                  查看
                </span>
            </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 一个月内 -->
      <div class="studyLogdivysj" v-if="moreList.length">
        <div class="studyLogdivList">
          <h6 class="studyLogdivListh6">一个月内</h6>
          <ul class="studyLogdivListoul">
            <li class="clearfix" v-for="(item, index) in moreList" :key="index">
              <dl class="studyLogdivListouldl clearfix">
                <dt><img :src="item.course_image" alt="" /></dt>
                <dd>
                  <h6>
                    <i>{{ item.course_type }}</i
                    ><span style="cursor: pointer" @click="jump_course(item)">{{
                      item.course_title
                    }}</span>
                  </h6>
                  <p>
                    <i v-if="item.lesson_type == 1">视频</i>
                    <i v-if="item.lesson_type == 2">直播</i>
                    <i v-if="item.lesson_type == 3">试卷</i>
                    <i v-if="item.lesson_type == 4">资料</i>
                    <i v-if="item.lesson_type == 5">作业</i>
                    <i v-if="item.lesson_type == 6">问卷</i>
                    <i v-if="item.lesson_type == 7">回放</i>
                    <i v-if="item.lesson_type == 8">电子书</i>
                    <i v-if="item.lesson_type == 9">签到</i>
                    <span style="cursor: pointer" @click="jump_lesson(item)">{{
                      item.lesson_title
                    }}</span>
                  </p>
                </dd>
              </dl>
              <div @click="jump_lesson(item)">
              <span
                class="studyLogdivListoulsp"
                v-if="item.lesson_type == 1 || item.lesson_type == 7"
              >
                观看至{{ item.study_time }}
              </span>
              <span
                class="studyLogdivListoulsp"
                :class="
                  item.live_status == '即将直播'
                    ? 'jjzb'
                    : item.live_status == '未开始'
                    ? 'wks'
                    : item.live_status == '已结束'
                    ? 'yjs'
                    : ''
                "
                v-if="item.lesson_type == 2 && item.is_replay == 0"
              >
                <img
                  v-if="item.live_status == '直播中'"
                  src="../../../../assets/images/living_blue.gif"
                  alt=""
                />{{ item.live_status }}
              </span>
              <span
                class="studyLogdivListoulsp"
                v-if="item.lesson_type == 3 || item.lesson_type == 5"
              >
                <span v-if="item.answer_id">
                  <span v-if="item.paper_finished != 1"> 继续做答 </span>
                  <span v-if="item.paper_finished == 1 && item.comment == ''"
                    >待点评</span
                  >
                  <span v-if="item.paper_finished == 1 && item.fraction">{{
                    item.fraction
                  }}</span>
                </span>
                <span v-else>查看</span>
              </span>
              <span
                class="studyLogdivListoulsp"
                v-if="
                  item.lesson_type == 4 ||
                  item.lesson_type == 6 ||
                  item.lesson_type == 8 ||
                  (item.lesson_type == 2 && item.is_replay == 1)
                "
              >
                查看
              </span>
              </div>
            </li>
          </ul>
          <div class="studyLogdivmore">
            <span @click="getMore">
              <i class="iconfont icon-more_btn"></i>{{ btnMessage }}
            </span>
          </div>
        </div>
      </div>
      <!-- 无数据 -->
      <div
        class="studyLogdivwsj"
        v-if="todayList.length == 0 && moreList.length == 0"
      >
        <div class="studyCoursekedetailswkc">
          <img src="../assets/images/study_zw.png" alt="" />
          <p>暂无学习记录，快去学习课程吧~</p>
          <span @click="$router.push('/search')">去学习</span>
        </div>
      </div>
    </div>
    <ClearLog ref="ClearLog"></ClearLog>
  </div>
</template>

<script>
import { recordList, recordPageList } from "../assets/api.js";
import {  downFile, ebookinfo,liveinfo } from "../../learn/assets/api";

import ClearLog from "../components/learn_record/clearLog.vue";
export default {
  name: "studyLog",
  components: {
    ClearLog,
  },
  data() {
    return {
      centerDialogVisible1: false,
      todayList: [],
      moreList: [],
      page: 1,
      limit: 20,
      btnMessage: "加载更多",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.todayList = [];
      this.moreList = [];
      recordList().then((res) => {
        if (res.code == 0) {
          if (res.data.today) {
            this.todayList = res.data.today;
          }
          if (res.data.more) {
            this.moreList = res.data.more;
          }
        }
      });
    },
    getMoreList() {
      const params = {
        page: this.page,
        limit: this.limit,
      };
      recordPageList(params).then((res) => {
        if (res.code == 0) {
          if (res.data.more || res.data.more.length != 0) {
            this.moreList = this.moreList.concat(res.data.more);
          }
          if (!res.data.more || res.data.more.length == 0) {
            this.btnMessage = "暂无更多";
          }
        } else {
          this.page--;
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getMoreList();
      }
    },
    clear() {
      this.$refs.ClearLog.openInit();
    },
    jump_course(it) {
      this.$router.push({
        path: "/learn",
        query: {
          course_id: it.course_id,
          metaType: 2,
        },
      });
    },
    jump_lesson(it) {
      // 1视频、2直播、3试卷、4资料、5作业、6问卷、7回放、8电子书、9签到
      //视频  回放
      if (it.lesson_type == 1 || it.lesson_type == 7) {
        this.$router.push({
          path: "/video",
          query: {
            course_id: it.course_id,
            lesson_id: it.lesson_id,
            modelFlag: 0,
          },
        });
      }
      //直播中
      if (it.lesson_type == 2 && it.live_sta_time - this.servertime < 0) {
        let params = {
          course_id: it.course_id,
          lesson_id: it.lesson_id,
        };
        liveinfo(params).then((res) => {
          if (res.code == 0) {
            window.open(
              "https://live.offcn.com/third_occchat?cid=" +
                res.data.channel_id +
                "&map=" +
                res.data.map +
                "&realkey=" +
                res.data.realkey +
                "&loginkey=1"
            );
          }
        });
      }
      //试卷
      if (it.lesson_type == 3) {
        console.log(it);
        //继续作答
        if (it.answer_id == 0 || it.paper_finished == 0) {
          this.$router.push({
            path: "/pape_do",
            query: {
              area_type: 0,
              paper_id: it.media_id,
              answer_id: it.answer_id,
              course_id: it.course_id,
              lesson_id: it.lesson_id,
            },
          });
        }
        //报告
        if (it.paper_finished == 1) {
          this.$router.push({
            path: "/paper_report",
            query: {
              answer_id: it.answer_id,
              paper_id: it.media_id,
            },
          });
        }
      }
      //资料-查看
      if (it.lesson_type == 4) {
        let params = {
          lesson_id: it.lesson_id,
          course_id: it.course_id,
        };
        downFile(params).then((res) => {
          if (res.code == 0) {
            window.location.href = res.data.downurl;
          }
        });
      }
      //ebook-查看
      if (it.lesson_type == 8) {
        let params = {
          lesson_id: it.lesson_id,
          course_id: it.course_id,
        };
        ebookinfo(params).then((res) => {
          if (res.code == 0) {
            window.open(res.data.downurl);
          }
        });
      }
      //作业
      if (it.lesson_type == 5) {
        this.$router.push({
          path: "/homework",
          query: {
            assignment_id: it.media_id,
            course_id: it.course_id,
            lesson_id: it.lesson_id,
          },
        });
      }
      //问卷
      if (it.lesson_type == 6) {
        if (it.paper_finished == 1) {
          this.$router.push({
            path: "/questionnaire_report",
            query: {
              survey_id: it.media_id,
              course_id: it.course_id,
              answer_id: it.answer_id,
              lesson_id: it.lesson_id,
            },
          });
        }
        if (it.paper_finished == 0) {
          this.$router.push({
            path: "/questionnaire",
            query: {
              survey_id: it.media_id,
              course_id: it.course_id,
              answer_id: it.answer_id,
              lesson_id: it.lesson_id,
            },
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/studyLog.scss";
</style>
