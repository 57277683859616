<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="400px"
      center
      :show-close="false"
      class="isDel"
    >
      <h6 class="isDelh6">确认清空记录吗？</h6>
      <p class="isDelp">清空后，历史学习记录不再显示</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">清空</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { recordClear } from "../../assets/api";
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    openInit() {
      this.dialogVisible = true;
    },
    submit() {
      recordClear().then((res) => {
        if (res.code == 0) {
          this.dialogVisible = false;
          this.$parent.getList();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 14px;
}
/deep/.isDel {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0 25px;
  }
  .isDelh6 {
    font: 18px/24px "Microsoft YaHei";
    color: #333333;
    text-align: center;
    padding-top: 50px;
  }
  .isDelp {
    font: 14px/20px "Microsoft YaHei";
    color: #777;
    text-align: center;
    padding-top: 12px;
  }
  .el-dialog__footer {
    padding: 50px 0 40px;
  }
  .el-button {
    border: none;
    padding: 0;
    width: 126px;
    height: 44px;
    border-radius: 22px;
    background: #eeeeee;
    color: #333;
    font-size: 16px;
  }
  .el-button + .el-button {
    margin-left: 20px;
    background: #507fff;
    color: #fff;
  }
}
</style>
